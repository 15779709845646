import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { Layout, SEO } from 'components';
import HomeHero from 'components/HomeHero';
import StructuredText from 'components/StructuredText';
import NewsletterSection from 'components/NewsletterSection';
import LawyersList from 'components/LawyersList';
import ArticlesList from 'components/ArticlesList';
import Right from 'components/Right';
import { Container, MainTitle, RightsGrid } from 'ui';
import Section from 'components/Section';
import { Button, Stack } from '@tymate/margaret';

const IntroContent = styled.div`
  ${({ theme }) => theme.fontStyles.bodyLarge}
  text-align: center;
  line-height: 1.5;
  max-width: 60ch;
  margin-left: auto;
  margin-right: auto;
`;

const IntroTitle = styled(MainTitle)`
  ${({ theme }) => theme.fontStyles.h1}
  font-weight: 400;
`;

const Homepage = ({
  data: {
    datoCmsHomepage,
    datoCmsGlobalInfo,
    allDatoCmsLawyer,
    allDatoCmsArticle,
    allDatoCmsRight,
  },
}) => (
  <>
    <SEO
      title={datoCmsHomepage?.metaTags?.title}
      description={datoCmsHomepage?.metaTags?.description}
    />

    <Layout>
      <HomeHero
        logo={datoCmsGlobalInfo?.logo}
        cover={datoCmsHomepage?.cover}
        title={<StructuredText data={datoCmsHomepage?.heroTitle} />}
      />

      <Section topDecorationColor="primary" bottomDecorationColor="primary">
        <Container>
          <Stack direction="column" gutterSize={2} size="full" alignX="stretch">
            <IntroTitle as="h2" variant="primary">
              <StructuredText data={datoCmsHomepage?.introTitle} />
            </IntroTitle>

            <IntroContent>
              <StructuredText data={datoCmsHomepage?.introContent} />
            </IntroContent>

            <Stack alignX="center" size="full">
              <Button variant="primary" to="/contact" as={Link}>
                {datoCmsHomepage?.introCta}
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Section>

      <Section
        variant="primaryDark"
        topDecorationColor="white"
        bottomDecorationColor="white"
      >
        <Container>
          <Stack direction="column" gutterSize={2} size="full" alignX="stretch">
            <MainTitle as="h2" variant="secondary">
              {datoCmsHomepage?.specialtiesTitle}
            </MainTitle>

            <RightsGrid variant="home">
              {(allDatoCmsRight?.edges || []).map(({ node }, id) => (
                <Right right={node} variant="home" key={id} />
              ))}
            </RightsGrid>
          </Stack>
        </Container>
      </Section>

      <Section topDecorationColor="primary" bottomDecorationColor="primary">
        <Stack direction="column" gutterSize={2} size="full" alignX="stretch">
          <Container>
            <MainTitle as="h2" variant="primary">
              <strong>{datoCmsHomepage?.teamTitle}</strong>
            </MainTitle>
          </Container>
          <LawyersList lawyers={allDatoCmsLawyer?.edges || []} />
        </Stack>
      </Section>

      <Section topDecorationColor="primary" bottomDecorationColor="primary">
        <Container>
          <Stack direction="column" gutterSize={2} size="full" alignX="stretch">
            <MainTitle as="h2" variant="primary">
              <strong>{datoCmsHomepage?.articlesTitle}</strong>
            </MainTitle>
            <ArticlesList
              articles={(allDatoCmsArticle?.edges || []).map(({ node }) => ({
                ...node,
              }))}
            />
            <Stack alignX="center">
              <Button variant="primary" as={Link} to="/actualites">
                Tous les articles
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Section>

      <NewsletterSection />
    </Layout>
  </>
);

export const query = graphql`
  query homepage {
    datoCmsGlobalInfo {
      logo {
        url
      }
    }
    datoCmsHomepage {
      heroTitle {
        value
      }
      articlesTitle
      teamTitle
      newsletterTitle
      specialtiesTitle
      introTitle {
        value
      }
      introContent {
        value
      }
      introCta
      metaTags {
        title
        description
      }
      cover {
        fluid(maxWidth: 1600) {
          base64
          srcSet
          src
        }
      }
    }
    allDatoCmsLawyer(sort: { fields: order }) {
      edges {
        node {
          id
          name
          description
          linkedinLink
          picture {
            gatsbyImageData(aspectRatio: 0.737)
          }
        }
      }
    }
    allDatoCmsArticle(limit: 3) {
      edges {
        node {
          id
          slug
          title
          kicker
          meta {
            firstPublishedAt
          }
          author {
            id
            name
            description
            picture {
              gatsbyImageData(aspectRatio: 1, width: 40)
            }
          }
          cover {
            url
            gatsbyImageData(aspectRatio: 2)
          }
          tags {
            id
            name
          }
        }
      }
    }
    allDatoCmsRight(sort: { fields: order }) {
      edges {
        node {
          slug
          name
          icon {
            url
          }
          cover {
            gatsbyImageData(aspectRatio: 1.77, layout: FULL_WIDTH)
          }
          specialties {
            name
            slug
          }
        }
      }
    }
  }
`;

export default Homepage;
