import React from 'react';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import { MainTitle, Container, Decoration } from 'ui';
import BackgroundImage from 'gatsby-background-image';
import decorationBottomWhite from 'images/section-bottom-white.svg';

const Wrapper = styled(BackgroundImage)`
  position: relative;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: ${({ theme }) => theme.textOnDark};
  text-align: center;

  ${({ heroBackground }) =>
    heroBackground &&
    `
      background-image: url(${heroBackground});
    `}
`;

const Inner = styled(Container)`
  position: relative;
  padding-top: ${({ theme }) => theme.mainNav.calculatedHeight};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  text-align: center;
  z-index: 2;
`;

const Logo = styled.img`
  width: 12em;
  display: block;
  max-width: 50vw;
  height: auto;
`;

const Backdrop = styled.div`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(8, 28, 52, 0.6);
  z-index: 1;
`;

const HomeHero = ({ title, cover, logo }) => (
  <Wrapper {...cover}>
    <Backdrop />
    <Decoration src={decorationBottomWhite} alt="" variant="bottom" />
    <Inner variant="main">
      <Stack direction="column" size="full" alignX="center" gutterSize={2}>
        <Logo src={logo?.url} alt="PhiLaw - Avocats et Notaires" />
        <MainTitle variant="light">{title}</MainTitle>
      </Stack>
    </Inner>
  </Wrapper>
);

export default HomeHero;
