import React from 'react';
import { Container, CardsList } from 'ui';
import ArticleCard from 'components/ArticleCard';

const ArticlesList = ({ articles, kind }) => (
  <Container>
    <CardsList variant="articles">
      {articles.map(article => (
        <ArticleCard article={article} key={article.slug} kind={kind} />
      ))}
    </CardsList>
  </Container>
);

export default ArticlesList;
